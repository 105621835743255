import {useSession} from "~/stores/session";
import type {JmiSearchParams, StoreInstance} from "~/composables/buildStore";
import type {PartnerUserRecord, UserRecord} from "~/utils/types";

export const usePartnerUsers = buildStore<"partnerUsers", UserRecord, PartnerUserRecord>(
	"partner_users",
	"/partner-users",
	"users",
	["partnerid"],
	{},
	{},
	{
		getCountSearchOptions(_: StoreInstance<UserRecord>, options: JmiSearchParams): JmiSearchParams {
			const session = useSession();

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			return options;
		},
		getSearchOptions(instanceData: StoreInstance<UserRecord>, options: JmiSearchParams) {
			options.attributesToHighlight = ['full_name', 'firstname', 'lastname', 'email'];

			options.filter.push(`usertype = 'partner'`);

			if (instanceData.queryFacets.partnerid !== undefined) {
				options.filter.push(`partner_id = '${instanceData.queryFacets.partnerid}'`);
			}

			return options;
		}
	}
);
