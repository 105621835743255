import {useSession} from "~/stores/session";
import type {FacetList, JmiSearchParams, StoreInstance} from "~/composables/buildStore";
import type {PropertyRecord, ExtendedPropertyRecord} from "~/utils/types";

export interface PropertyFacets extends FacetList {
	address1: string;
	managed: boolean;
}

export const useProperties = buildStore<"properties", PropertyRecord, ExtendedPropertyRecord, PropertyFacets, {
	panelOpen: boolean,
}, Record<string, never>, {
	propertyEdit: () => void,
	propertyStartMove: (direction: string) => void,
	setPanel: (isOpen: boolean) => void,
	openPanel: (recordId: number) => void
}>(
	"properties",
	"/properties",
	"properties",
	["managed"],
	{
		panelOpen: false,
	},
	{},
	{
		getCountSearchOptions(_: StoreInstance<PropertyRecord, PropertyFacets>, options: JmiSearchParams<PropertyFacets>) {
			const session = useSession();

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			return options;
		},
		getSearchOptions(instanceData: StoreInstance<PropertyRecord, PropertyFacets>, options: JmiSearchParams<PropertyFacets>) {
			const session = useSession();

			options.attributesToHighlight = ['address1', 'address2', 'city', 'county', 'postcode'];

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			if (instanceData.queryFacets.managed !== undefined) {
				options.filter.push('managed = ' + (instanceData.queryFacets.managed ? 'true' : 'false'));
			}

			return options;
		},
		propertyEdit() {
			navigateTo("/properties/edit/" + this.currentRecord?.id);
			this.setPanel(false);
		},
		propertyStartMove (direction : string) {
			navigateTo({
				path: '/moves/add',
				query: {
					direction: direction,
					property_id: this.currentRecord?.id
				}
			});
			this.setPanel(false);
		},
		setPanel(isOpen : boolean) {
			this.panelOpen = isOpen;
		},
		openPanel(recordId : number) {
			this.openRecord(recordId);
			this.setPanel(true);
		}
	}
);
