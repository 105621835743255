import {useSession} from "~/stores/session";
import type {FacetList, JmiSearchParams, StoreInstance} from "~/composables/buildStore";
import type {ExtendedMoveRecord, MoveDirection, MoveEvent, MoveEventType, MoveRecord, PropertyInputs, OccupantInputs, InterimPeriodInputs, VoidSwitchInputs, MoveOutEventInputs, PendingChanges} from "~/utils/types";
import {isExtendedMoveRecord} from "~/utils/types";
import type {MoveStatus} from "~/utils/MoveStatuses";
import type {VoidEnergySwitchStatus} from "~/utils/voidEnergySwitchStatus";
import {formatISO} from "date-fns";
import type {VoidMeterReadingStatus} from "~/utils/voidMeterReadingStatus";

export interface MoveFacets extends FacetList {
	status: MoveStatus[]
	managed: boolean[],
	types: MoveEventType[],
	directions: MoveDirection[],
	created_at: [string, string],
	movedate: [string, string],
	is_pending: boolean | boolean[],
	"void.energy_switch": boolean,
	"void.status": VoidEnergySwitchStatus[],
	"void.start_date": [string, string],
	void_start_date_confirmed: boolean[],
	void_requested_date: [string, string],
	"void.meter_reading_status": VoidMeterReadingStatus[],
	"void.is_missing_information": boolean[],
}

export const useMoves = buildStore<"moves", MoveRecord, ExtendedMoveRecord, MoveFacets, Record<string, never>, Record<string, never>, {
	addOccupant: (moveId: number, occupant: OccupantInputs, movedirection: MoveDirection) => Promise<ExtendedMoveRecord>,
	editOccupant: (occupantId: number, occupant: OccupantInputs) => Promise<ExtendedMoveRecord>,
	deleteOccupant: (occupantId: number) => Promise<ExtendedMoveRecord>
	getMoveinEvent: (record: MoveRecord | undefined) => MoveEvent | undefined,
	getMoveoutEvent: (record: MoveRecord | undefined) => MoveEvent | undefined,
	editPartnerSubmissionNotes: (id: string | number, partner_submission_notes: string | undefined | null) => Promise<ExtendedMoveRecord>,
	addMoveEvent: (id: number, direction: MoveDirection, type: MoveEventType, movedate: Date, movedate_confirmed: boolean, property: PropertyInputs) => Promise<ExtendedMoveRecord | undefined>,
	editMoveDate: (id: number, direction: MoveDirection, movedate: Date | null, movedate_confirmed: boolean) => Promise<ExtendedMoveRecord | undefined>,
	rejectMove: (id: number) => Promise<PendingChanges | undefined>,
	addVoidEnergySwitch: (id: number, interimData: InterimPeriodInputs, voidData: VoidSwitchInputs, moveOutData: MoveOutEventInputs) => Promise<ExtendedMoveRecord | undefined>,
	cancelVoidEnergySwitch: (id: number) => Promise<ExtendedMoveRecord | undefined>,
	pauseUnpauseMove: (id: string, formData: unknown) => Promise<ExtendedMoveRecord>,
}>(
	"moves",
	"/moves",
	"moves",
	[
		"types",
		"directions",
		"status",
		"managed",
		"moveout.movedate_confirmed",
		"void.status",
		"void.meter_reading_status",
		"void.is_missing_information",
	],
	{},
	{},
	{
		getMoveinEvent(record: MoveRecord | ExtendedMoveRecord | undefined): MoveEvent | undefined {
			const intl = new Intl.DateTimeFormat('en-GB');

			if (!record || !record.movein) {
				return undefined;
			}

			const event = record.movein;
			const date = event.movedate ? new Date(event.movedate * 1000) : undefined;
			let type: string | undefined = event.type;
			let address: string | null;
			let property: PropertyInputs | undefined;

			if (type === "sale") {
				type = "purchase";
			}

			if (isExtendedMoveRecord(record)) {
				address = record.movein.property?.label ?? null;
				property = record.movein.property;
			} else {
				address = record._formatted?.movein.address ?? record.movein.address;
				property = record.movein.property;
			}

			return {
				type: event.type,
				formatted_type: type ? type : '',
				movedate: event.movedate ? intl.format(date) : 'Unknown',
				managed: event.managed,
				movedate_confirmed: event.movedate_confirmed,
				address: address,
				property: property
			}
		},

		getMoveoutEvent(record: MoveRecord | ExtendedMoveRecord | undefined): MoveEvent | undefined {
			const intl = new Intl.DateTimeFormat('en-GB');

			if (!record || !record.moveout) {
				return undefined;
			}

			const event = record.moveout;
			const date = event.movedate ? new Date(event.movedate * 1000) : undefined;
			const type: string | undefined = event.type;
			let address: string | null;
			let property: PropertyInputs | undefined;

			if (isExtendedMoveRecord(record)) {
				address = record.moveout.property?.label ?? null;
				property = record.moveout.property;
			} else {
				address = record._formatted?.moveout.address ?? record.moveout.address;
				property = record.moveout.property;
			}

			return {
				type: event.type,
				formatted_type: type ? type : undefined,
				movedate: event.movedate ? intl.format(date) : 'Unknown',
				managed: event.managed,
				movedate_confirmed: event.movedate_confirmed,
				address: address,
				property: property
			}
		},

		getCountSearchOptions(instanceData: StoreInstance<MoveRecord, MoveFacets>, options: JmiSearchParams<MoveFacets>) {
			const session = useSession();

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			const is_pending = instanceData.queryFacets.is_pending ?? false;
			if (is_pending) {
				options.filter.push("is_pending = true");
			} else {
				options.filter.push("is_pending = false");
			}

			if (instanceData.queryFacets["void.energy_switch"] !== undefined) {
				options.filter.push(`void.energy_switch = ${instanceData.queryFacets["void.energy_switch"] ? 'true' : 'false'}`);
			}

			return options;
		},

		async editMoveDate(id: number, direction: MoveDirection, movedate: Date | null, movedate_confirmed: boolean): Promise<ExtendedMoveRecord | undefined> {
			const values = {
				movedirection: direction,
				movedate: movedate ? formatISO(movedate, {representation: "date"}) : null,
				movedate_confirmed: movedate_confirmed
			};

			return this.submit?.(`/moves/edit-move-date/${id}`, 'PATCH', id, values);
		},

		async rejectMove(id: number): Promise<PendingChanges | undefined> {
			return this.submit?.<PendingChanges>(`/moves/reject/${id}`, 'PATCH');
		},

		async editPartnerSubmissionNotes(id: string | number, partner_submission_notes: string | undefined | null): Promise<ExtendedMoveRecord | undefined> {
			const values = {
				partner_submission_notes: partner_submission_notes ?? null
			};
			return this.submit?.(`/moves/submit_additional_info/${id}`, 'PATCH', id, values);
		},

		async addOccupant(moveId: number, occupant: OccupantInputs, movedirection: MoveDirection): Promise<ExtendedMoveRecord | undefined> {
			const values = {
				...occupant,
				movedirection: movedirection
			};
			return (await this.submit?.(`/additional-occupants/${moveId}`, 'POST', moveId, values));
		},

		async addVoidEnergySwitch(id: number, interimData: InterimPeriodInputs, voidData: VoidSwitchInputs, moveOutData: MoveOutEventInputs): Promise<ExtendedMoveRecord | undefined> {
			return this.submit?.(`/moves/add-void-energy-switch/${id}`, 'POST', undefined, {
				interim: interimData,
				void: voidData,
				moveout: moveOutData,
			});
		},

		async cancelVoidEnergySwitch(id: number): Promise<ExtendedMoveRecord | undefined> {
			return this.submit?.(`/moves/cancel-void-energy-switch/${id}`, 'POST', undefined);
		},

		async addMoveEvent(id: number, direction: MoveDirection, type: MoveEventType, movedate: Date, movedate_confirmed: boolean, property: PropertyInputs): Promise<ExtendedMoveRecord | undefined> {
			return this.submit?.(`/moves/add-event/${id}`, 'PATCH', undefined, {
				direction,
				type,
				movedate: formatISO(movedate, {representation: "date"}),
				movedate_confirmed,
				property,
			});
		},

		async editOccupant(occupantId: number, occupant: OccupantInputs): Promise<ExtendedMoveRecord | undefined> {
			return (await this.submit?.(`/additional-occupants/${occupantId}`, 'PATCH', occupantId, occupant));
		},
		async deleteOccupant(occupantId: number): Promise<ExtendedMoveRecord | undefined> {
			return (await this.submit?.(`/additional-occupants/${occupantId}`, 'DELETE', occupantId));
		},

		getSearchOptions(instanceData: StoreInstance<MoveRecord, MoveFacets>, options: JmiSearchParams<MoveFacets>, instanceName: string) {
			const session = useSession();
			let filter: string[] = [];

			options.attributesToHighlight = ['movein.address', 'moveout.address', 'customer.full_name_and_title'];

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			if (instanceName !== "refresh-pending-changes") {
				const is_pending = instanceData.queryFacets.is_pending ?? false;
				if (is_pending) {
					options.filter.push("is_pending = true");
				} else {
					options.filter.push("is_pending = false");
				}
			}

			if (instanceData.queryFacets["void.energy_switch"] !== undefined) {
				options.filter.push(`void.energy_switch = ${instanceData.queryFacets["void.energy_switch"] ? 'true' : 'false'}`);
			}

			if (instanceData.queryFacets["void.status"] !== undefined && instanceData.queryFacets["void.status"]) {
				filter = [];
				instanceData.queryFacets["void.status"].forEach((value: string) => {
					filter.push(`void.status = "${value}"`);
				});
				options.filter.push(filter.join(" OR "));
			}

			if (instanceData.queryFacets.types !== undefined) {
				filter = [];
				instanceData.queryFacets.types.forEach((value: string) => {
					filter.push(`types = "${value}"`);
				});
				options.filter.push(filter.join(" OR "));
			}

			if (instanceData.queryFacets.status !== undefined) {
				filter = [];
				instanceData.queryFacets.status.forEach((value: string) => {
					filter.push(`status = "${value}"`);
				});
				options.filter.push(filter.join(" OR "));
			}

			if (instanceData.queryFacets.managed !== undefined) {
				filter = [];
				instanceData.queryFacets.managed.forEach((value: boolean) => {
					filter.push(`managed = ${value ? 'true' : 'false'}`);
				});
				options.filter.push(filter.join(" OR "));
			}

			if (instanceData.queryFacets.directions !== undefined) {
				filter = [];
				instanceData.queryFacets.directions.forEach((value: string) => {
					if (value === "in") {
						filter.push(`directions = "in"`);
					} else {
						filter.push(`directions = "out"`);
					}
				});
				options.filter.push(filter.join(" OR "));
			}

			if (instanceData.queryFacets.created_at !== undefined && instanceData.queryFacets.created_at) {
				const result = instanceData.queryFacets.created_at;
				const start = Math.floor((new Date(result[0])).valueOf() / 1000);
				const end = Math.floor((new Date(result[1])).valueOf() / 1000);
				options.filter.push(`created_at >= ${start} AND created_at <= ${end}`);
			}

			if (instanceData.queryFacets.movedate !== undefined && instanceData.queryFacets.movedate) {
				const result = instanceData.queryFacets.movedate;
				const start = Math.floor((new Date(result[0])).valueOf() / 1000);
				const end = Math.floor((new Date(result[1])).valueOf() / 1000);
				options.filter.push(`dates >= ${start} AND dates <= ${end}`);
			}

			if (instanceData.queryFacets["void.start_date"] !== undefined && instanceData.queryFacets["void.start_date"]) {
				const result = instanceData.queryFacets["void.start_date"];
				const start = Math.floor((new Date(result[0])).valueOf() / 1000);
				const end = Math.floor((new Date(result[1])).valueOf() / 1000);
				options.filter.push(`void.start_date >= ${start} AND void.start_date <= ${end}`);
			}

			if (instanceData.queryFacets["void.meter_reading_status"] !== undefined && instanceData.queryFacets["void.meter_reading_status"]) {
				filter = [];
				instanceData.queryFacets["void.meter_reading_status"].forEach((value: string) => {
					filter.push(`void.meter_reading_status = "${value}"`);
				});
				if (filter.length) {
					options.filter.push(filter.join(" OR "));
				}
			}

			if (instanceData.queryFacets.void_start_date_confirmed !== undefined && instanceData.queryFacets.void_start_date_confirmed) {
				filter = [];
				instanceData.queryFacets.void_start_date_confirmed.forEach((value: boolean) => {
					filter.push(`moveout.movedate_confirmed = ${value ? 'true' : 'false'}`);
				});
				options.filter.push(filter.join(" OR "));
			}

			if (instanceData.queryFacets["void.is_missing_information"] !== undefined) {
				filter = [];
				instanceData.queryFacets["void.is_missing_information"].forEach((value: boolean) => {
					filter.push(`void.is_missing_information = ${value ? 'true' : 'false'}`);
				});
				if (filter.length) {
					options.filter.push(filter.join(" OR "));
				}
			}

			return options;
		},
		async pauseUnpauseMove(moveId: string, formData: unknown) {
			return this.submit?.(`/move/edit-hold-status/${moveId}`, 'POST', undefined, formData);
		},
	}
);
