import {useSession} from "~/stores/session";
import type {FacetList, JmiSearchParams, StoreInstance} from "~/composables/buildStore";
import type {MoveDirection, CooRecord, CooStatus, CooType, CooAdditionalInformationInputs} from "~/utils/types";
import useJmiFetch from "~/composables/use-jmi-fetch";

export interface CooFacets extends FacetList {
	status: CooStatus | null;
	errors: "meter_details" | "supplierid" | "movedate";
	has_errors: boolean;
	countable_errors: CooFacets["errors"];
	types: CooType | CooType[];
	moveid: number | number[];
	direction: MoveDirection;
	has_movedate: boolean;
	scheduled: [string, string];
	can_submit_readings: boolean;
}

export const useCoos = buildStore<"coos", CooRecord, CooRecord, CooFacets, Record<string, never>, {
	allStatuses: () => CooStatus[]
},
	{
		exportCoo(moveEventId: number): Promise<unknown>,
		saveMeterReading: (readings: CooAdditionalInformationInputs[], cooId: number) => Promise<CooRecord | undefined>
	}
>(
	"coos",
	"/coos",
	"coos",
	["status", "errors"],
	{},
	{
		allStatuses: () => {
			return [
				"Missing Information",
				"Sendable",
				"Sent",
				"On Hold",
				"Unable to Send",
			];
		}
	},
	{
		getMatchesQuery(instanceData: StoreInstance<CooRecord, CooFacets>, item: CooRecord) {
			if (instanceData.queryFacets.status !== undefined) {
				if (item.status !== instanceData.queryFacets.status) {
					return false;
				}
			} else if (typeof instanceData.queryFacets.id === "undefined") {
				if (this.allStatuses.indexOf(item.status) === -1) {
					return false;
				}
			}

			if (instanceData.queryFacets.errors !== undefined) {
				if (item.errors.indexOf(instanceData.queryFacets.errors) === -1) {
					return false;
				}
			}

			return true;
		},
		getCountSearchOptions(instanceData: StoreInstance<CooRecord, CooFacets>, options: JmiSearchParams<CooFacets>) {
			const session = useSession();

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			if (instanceData.queryFacets.countable_errors !== undefined) {
				options.filter.push('errors = "' + instanceData.queryFacets.countable_errors + '"');
			}

			return options;
		},
		getSearchOptions(instanceData: StoreInstance<CooRecord, CooFacets>, options: JmiSearchParams<CooFacets>) {
			const session = useSession();

			options.attributesToHighlight = ["address"];

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			if (instanceData.queryFacets.status !== undefined && instanceData.queryFacets.status !== null) {
				options.filter.push('status = "' + instanceData.queryFacets.status + '"');
			} else if (instanceData.queryFacets.status === undefined || instanceData.queryFacets.status === null) {
				const allStatuses: string = this.allStatuses.map((item: string) => `"${item}"`).join(", ");
				options.filter.push(`status IN [${allStatuses}]`);
			}

			if (instanceData.queryFacets.errors !== undefined) {
				options.filter.push('errors = "' + instanceData.queryFacets.errors + '"');
			}

			if (instanceData.queryFacets.moveid !== undefined) {
				let move_ids = instanceData.queryFacets.moveid;
				if (!Array.isArray(move_ids)) {
					move_ids = [move_ids];
				}

				options.filter.push('moveid IN [' + move_ids.join(",") + ']');
			}

			if (instanceData.queryFacets.types !== undefined) {
				let types = instanceData.queryFacets.types;
				if (!Array.isArray(types)) {
					types = [types];
				}

				options.filter.push('type IN [' + types.map(type => '"' + type + '"').join(",") + ']');
			}

			if (instanceData.queryFacets.direction !== undefined) {
				options.filter.push('direction = "' + instanceData.queryFacets.direction + '"');
			}

			if (instanceData.queryFacets.scheduled !== undefined) {
				const result = instanceData.queryFacets.scheduled;
				const start = Math.floor(new Date(result[0]).valueOf() / 1000);
				const end = Math.floor(new Date(result[1]).valueOf() / 1000);
				options.filter.push(`scheduled > ${start} AND scheduled < ${end}`);
			}

			return options;
		},
		async exportCoo(moveEventId: number) {
			const {get} = useJmiFetch();
			return await get("/coo/export/" + moveEventId);
		},
		async saveMeterReading(additionalInformation: CooAdditionalInformationInputs[], cooId: number) {
			return this.submit?.<CooRecord>('/coos/' + cooId + '/additional-information', 'PATCH', undefined, {
				additional_information: additionalInformation,
				coo_id: cooId,
			});
		},
	}
);
