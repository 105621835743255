import * as Sentry from "@sentry/vue";
import {defineNuxtPlugin} from "nuxt/app";
import type {Router} from "vue-router";

export default defineNuxtPlugin((nuxtApp) => {
	const config = useRuntimeConfig()

	Sentry.init({
		enabled: config.public["sentryEnabled"] as boolean,
		app: nuxtApp.vueApp,
		autoSessionTracking: true,
		debug: config.public["appEnv"] !== 'production',
		dsn: config.public["sentryDsn"],
		environment: config.public["appEnv"],
		integrations: [
			new Sentry.BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(nuxtApp["$router"] as Router),
			}),
			new Sentry.Replay(),
		],
		trackComponents: true,
		hooks: ['activate', 'create', 'destroy', 'mount', 'update'],
		sampleRate: 1,
		tracesSampleRate: 0.2,
		replaysSessionSampleRate: 0,
		replaysOnErrorSampleRate: 1,
	});
});
