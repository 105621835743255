import {_GettersTree, acceptHMRUpdate, defineStore, StateTree, StoreActions} from 'pinia';
import {useSession} from "~/stores/session";
import {MatchingStrategies, MeiliSearch, SearchParams, SearchResponse} from "meilisearch";
import type {FetchOptions} from "ofetch";
import useJmiFetch from "~/composables/use-jmi-fetch";
import {isFetchError, isJmiRecord, KnownIndexes, isError, isPendingChanges, DeletedRecord, isDeletedRecord, PendingChanges, StoreRecordMap} from "~/utils/types";
import type {UnwrapRef} from "vue";
import type {Crop, Highlight, Pagination, Query} from "meilisearch/src/types/types";
import {useMoves} from "~/stores/moves";
import {useProperties} from "~/stores/properties";
import {useCoos} from "~/stores/coos";
import {useMovers} from "~/stores/movers";
import {usePartnerUsers} from "~/stores/partnerUsers";
import {subSeconds} from "date-fns";
import {isProduction} from "~/utils/isProduction";

export interface StoreInstanceOptions<F extends FacetList> {
	query?: string,
	queryFacets?: Partial<F>,
	useGlobalCounts?: boolean,
	sort?: {
		[facet in keyof F]?: 'asc' | 'desc'
	},
	perPage?: number,
	useCachedFacets?: boolean,
}

export interface StoreInstance<K extends JmiRecord, F extends FacetList = FacetList> {
	booted: boolean,
	hasCompletedFirstSearch: boolean,
	query: string,
	previousQuery: undefined | string,
	deletedRecords: Array<string>,
	queryData: K[],
	queryFacets: Partial<F>,
	useGlobalCounts?: boolean | undefined,
	currentPage: number,
	countFetchedAt?: number | undefined,
	sort?: {
		[facet in keyof F]?: 'asc' | 'desc'
	},
	totalHits: number,
	totalHitsActive: number,
	abortControllers: AbortController[]
	perPage: number,
	facetDistribution: {
		[facet in keyof F]?: {
			[prop: string]: number
		}
	},
	isSearching: boolean,
}

export interface FacetList {
	id: string | number | Array<string | number> | undefined | null,
	partnerid: string | Array<string> | undefined
}

export type ValidationErrors = {
	[prop: string]: string[]
}

export interface JmiRecord {
	id: string | number,
	wasRecentlyCreated?: boolean,
	updated_at: number
}

export interface GenericJmiStoreState<K extends JmiRecord, ExtendedK extends K, F extends FacetList> {
	instances: Record<string, StoreInstance<K, F>>,
	openingRecord: boolean,
	requestCode: number,
	currentRecord: ExtendedK | undefined,
	isSubmitting: boolean,
	isRefreshingPendingChanges: boolean,
	mostRecentQueriesMs: number[],
	pendingChanges: {
		[id: string]: K | DeletedRecord
	} | undefined,
	validationErrors: ValidationErrors,
	index: undefined | string,
	searchClient: MeiliSearch | undefined,
}

export type JmiStoreState<K extends JmiRecord, ExtendedK extends K, F extends FacetList, S extends StateTree = Record<string, never>> = GenericJmiStoreState<K, ExtendedK, F> & S;

export interface JmiStoreGetters<K extends JmiRecord> {
	averageQueryLength: () => number,
	activeCount: () => Record<string, number>
}

export interface GenericJmiStoreActions<K extends JmiRecord, ExtendedK extends K, F extends FacetList> extends StoreActions<any> {
	boot: (instance: string, options?: StoreInstanceOptions<F>) => Promise<void>,
	shutdown: (instance: string) => void,
	openRecord: (id: string | number) => void,
	closeRecord: () => void,
	setSort: (instance: string, sort: object, refresh?: boolean) => void,
	setFacet: <SingleFacet extends keyof F = any>(instance: string, facet: SingleFacet, value: F[SingleFacet] | undefined, refresh?: boolean) => void,
	search: (instance: string, query: string, extra?: JmiSearchParams<F>) => Promise<void>,
	getCounts: (instance: string, query: string) => Promise<void>,
	searchAndResetPage: (instance: string, query: string) => Promise<void>,
	refresh: () => Promise<void>,
	previousPage: (instance: string) => void,
	nextPage: (instance: string) => void,
	create: (values: K) => Promise<ExtendedK>,
	edit: (id: string | number, values: Partial<K>) => Promise<ExtendedK>,
	delete: (id: string | number) => Promise<void>,
	hasNextPage: (instance: string) => boolean,
	showLoading: (instance: string) => boolean,
	count: (instance: string, facet?: keyof F, facetValue?: string | string[]) => number,
	submit: <SubmitResponse extends object = object>(url: string | undefined, method: string, id?: number | string | undefined, body?: object, isNew?: boolean) => Promise<SubmitResponse | undefined>,
	runMeilisearchSearch: (query: string, options: JmiSearchParams<F>) => Promise<SearchResponse<K>>,
	runBackendSearch: (instance: string, query: string, options: JmiSearchParams<F>) => Promise<SearchResponse<K>>,
	getMatchesQuery: (instanceData: StoreInstance<K, F>, record: K) => boolean,
	getCountSearchOptions: (instanceData: StoreInstance<K, F>, options: JmiSearchParams<F>) => JmiSearchParams<F>,
	getSearchOptions: (instanceData: StoreInstance<K, F>, options: JmiSearchParams<F>, instanceName: string) => JmiSearchParams<F>,
	addPendingChange: (record: K | DeletedRecord, isNew: boolean) => void,
	restartMeiliSearchClient: () => this,
	refreshPendingChanges: () => Promise<void>,
	removePendingChange: (id: string | number) => void,
	storePendingChanges: (records: Array<ExtendedK | DeletedRecord>, isNew: boolean) => void,
	handlePendingChangesResponse: (response: object, isNew: boolean) => void,
	needsGlobalCounts: (instance: string) => boolean,
	updateInstance: (instance: string, data: Partial<StoreInstance<K, F>>) => void,
}

export type JmiStoreActions<K extends JmiRecord, ExtendedK extends K, F extends FacetList, A extends {} = {}> = GenericJmiStoreActions<K, ExtendedK, F> & A;

export type JmiSearchParams<F extends FacetList = FacetList> = Query &
	Pagination &
	Highlight &
	Crop & {
	filter: Array<string | string[]>,
	sort?: string[]
	facets?: Array<keyof F>;
	attributesToRetrieve?: string[]
	showMatchesPosition?: boolean
	matchingStrategy?: MatchingStrategies
	hitsPerPage?: number
	page?: number
};

export default function <Id extends keyof StoreRecordMap, K extends JmiRecord, ExtendedK extends K, F extends FacetList = FacetList, S extends StateTree = object, G = object, A extends object = object>(storeId: Id, endpoint: string, indexKey: KnownIndexes | undefined, facets: Array<keyof F>, state: S, getters: G, actions: A & Partial<GenericJmiStoreActions<K, ExtendedK, F>>) {
	const store = defineStore<Id, JmiStoreState<K, ExtendedK, F, S>, JmiStoreGetters<K> & _GettersTree<JmiStoreState<K, ExtendedK, F, S>> & G, JmiStoreActions<K, ExtendedK, F, A>>(storeId, {
		state: () => ({
			instances: {
				default: {
					booted: false,
					hasCompletedFirstSearch: false,
					query: "",
					previousQuery: undefined,
					deletedRecords: [],
					queryData: [],
					queryFacets: {},
					useGlobalCounts: undefined,
					currentPage: 1,
					totalHits: 0,
					totalHitsActive: 0,
					perPage: 20,
					facetDistribution: {},
					countFetchedAt: undefined,
					isSearching: false,
					abortControllers: [],
				}
			},
			openingRecord: false,
			requestCode: 200,
			index: undefined as string | undefined,
			currentRecord: undefined,
			searchClient: undefined,
			isSubmitting: false,
			isRefreshingPendingChanges: false,
			pendingChanges: undefined,
			validationErrors: {},
			mostRecentQueriesMs: [indexKey ? 199 : 201], // If it's the backend, let's assume it's slow until proven otherwise.
			...state
		}),

		getters: {
			averageQueryLength(): number {
				return this.mostRecentQueriesMs.reduce((previousValue, currentValue) => {
					return previousValue + currentValue;
				}, 0) / this.mostRecentQueriesMs.length;
			},
			activeCount(): Record<string, number> {
				const result: Record<string, number> = {};
				for (const [instance, instanceData] of Object.entries(this.instances)) {
					result[instance] = instanceData.totalHitsActive;
				}

				return result;
			},
			...getters
		},

		actions: {
			getIndexName() {
				if (!this.index && indexKey) {
					const session = useSession();
					this.index = session.user?.indexes[indexKey];
				}

				return this.index;
			},

			showLoading(instance: string): boolean {
				return !!this.instances[instance]?.isSearching && this.averageQueryLength > 200;
			},

			count(instance: string, facet?: keyof F, facetValue?: string | string[]): number {
				if (facet && facetValue) {
					const facetDistribution = this.instances[instance]?.facetDistribution;

					if (facetDistribution !== undefined && typeof facetDistribution[facet] !== 'undefined') {
						const values = typeof facetValue === 'string' ? [facetValue] : facetValue;
						return values.reduce((previousValue, currentValue) => {
							const singleFacet = facetDistribution[facet];
							if (singleFacet) {
								return previousValue + (singleFacet[currentValue] ?? 0);
							} else {
								return previousValue;
							}
						}, 0);
					} else {
						return 0;
					}
				} else {
					return this.instances[instance]?.totalHits ?? 0;
				}
			},
			hasNextPage(instance: string) {
				const currentMax = this.instances[instance].currentPage * this.instances[instance].perPage;
				return this.instances[instance].totalHitsActive > currentMax;
			},
			needsGlobalCounts(instance: string): boolean {
				const instanceData = this.instances[instance];

				if (instanceData) {
					if (instanceData.useGlobalCounts !== undefined) {
						return instanceData.useGlobalCounts;
					} else {
						return Object.keys(instanceData.queryFacets).length > 0 || !indexKey;
					}
				} else {
					return false;
				}
			},

			storePendingChanges(records: Array<ExtendedK | DeletedRecord>, isNew: boolean) {
				for (const record of records) {
					if (isDeletedRecord(record)) {
						this.addPendingChange(record, false);

						if (this.currentRecord?.id === record.id) {
							this.closeRecord();
						}
					} else {
						// If the record wasn't updated in the last 30 seconds, then it hasn't actually been updated.
						// This helps prevents developer-side mistakes by throwing an error which will be noticed during development.
						const thirtySecondsAgo = subSeconds(new Date(), 30).getTime();
						const updatedAt = (record.updated_at * 1000);
						if (updatedAt < thirtySecondsAgo && !isProduction()) {
							throw new Error(`${indexKey} record #${record.id} was not updated recently enough to be considered updated, but it should have been (updated_at: ${updatedAt / 1000}, now: ${thirtySecondsAgo / 1000})`);
						}

						this.addPendingChange(record, isNew);

						if (this.currentRecord?.id === record.id) {
							this.currentRecord = record as UnwrapRef<ExtendedK>;
						}
					}
				}
			},

			handlePendingChangesResponse(response: object, isNew: boolean) {
				const isExtendedK = (value: unknown): value is ExtendedK => {
					return isJmiRecord(value);
				};
				const isExtendedKArray = (value: unknown): value is ExtendedK[] => {
					return Array.isArray(value) && value.every(isExtendedK);
				};
				const isExtendedKList = (value: unknown): value is { records: ExtendedK[] } => {
					return typeof value === 'object' && value !== null && 'records' in value && isExtendedKArray((value as { records: unknown }).records);
				};

				if (isPendingChanges(response)) {
					for (const [index, records] of Object.entries(response)) {
						if (index === "_changes") {
							// We don't need to do anything with this.
						} else if (index === storeId) {
							if (isExtendedKArray(records)) {
								this.storePendingChanges(records, isNew);
							}
						} else {

							switch (index) {
								case "coos":
									useCoos().handlePendingChangesResponse(records, isNew);
									break;
								case "movers":
									useMovers().handlePendingChangesResponse(records, isNew);
									break;
								case "moves":
									useMoves().handlePendingChangesResponse(records, isNew);
									break;
								case "partner_users":
									usePartnerUsers().handlePendingChangesResponse(records, isNew);
									break;
								case "properties":
									useProperties().handlePendingChangesResponse(records, isNew);
									break;
							}
						}
					}
				} else if (isExtendedK(response)) {
					this.storePendingChanges([response], isNew);
				} else if (isExtendedKList(response)) {
					this.storePendingChanges(response.records, isNew);
				} else if (isExtendedKArray(response)) {
					this.storePendingChanges(response, isNew);
				}
			},

			async submit<K extends any = any>(url: string | undefined = undefined, method: string, id: number | string | undefined = undefined, body: any = undefined, isNew = false): Promise<K | undefined> {
				const {fetch} = useJmiFetch();

				this.isSubmitting = true;
				this.validationErrors = {};

				if (!url) {
					url = endpoint + (id ? ('/' + id) : '');
				}

				try {
					const response = await fetch(url, {
						method,
						body
					});

					if (method !== 'GET') {
						this.handlePendingChangesResponse(response, isNew);
					}

					return response as K;
				} catch (e) {
					this.requestCode = e.statusCode;

					if (isFetchError(e)) {
						if (e.statusCode === 422 && e.data && e.data.errors) {
							this.validationErrors = e.data.errors;
						} else {
							this.validationErrors = {unknown: ["An unknown error occurred."]};
							throw e;
						}
					} else {
						this.validationErrors = {unknown: ["An unknown error occurred."]};
						throw e;
					}
				} finally {
					this.isSubmitting = false;
				}
			},

			async create<K extends JmiRecord>(values: K): Promise<ExtendedK | undefined> {
				const response = await this.submit<PendingChanges>(undefined, 'POST', undefined, values, true);

				if (isPendingChanges(response)) {
					const records = response[storeId];
					if (records) {
						return records[0] as unknown as ExtendedK;
					}
				}

				return;
			},

			async edit<K extends JmiRecord>(id: string | number, values: Partial<K>): Promise<ExtendedK | undefined> {
				const response = await this.submit<PendingChanges>(undefined, 'PATCH', id, values);
				if (isPendingChanges(response)) {
					const records = response[storeId];
					const record = records?.find(record => (record as unknown as ExtendedK).id === id);
					return record as unknown as ExtendedK | undefined;
				}

				return;
			},

			async delete(id: string | number): Promise<void> {
				await this.submit(undefined, 'DELETE', id);
			},

			nextPage(instance: string): void {
				if (this.hasNextPage(instance)) {
					this.instances[instance].currentPage++;
					void this.search(instance, this.instances[instance].query);
				}
			},

			updateInstance(instance: string, data: Partial<StoreInstance<K, F>>) {
				const instanceData = this.instances[instance];
				if (instanceData) {
					this.instances[instance] = {...instanceData, ...data};
				} else {
					throw new Error(`Trying to update instance data for an instance that has not been booted (${instance}).`);
				}
			},

			previousPage(instance: string): void {
				const instanceData = this.instances[instance];
				if (instanceData && instanceData.currentPage > 1) {
					this.updateInstance(instance, {
						currentPage: instanceData.currentPage - 1,
					})
					void this.search(instance, instanceData.query);
				}
			},

			async refresh() {
				await Promise.all(Object.keys(this.instances).map((instance): Promise<void> | undefined => {
					const instanceData = this.instances[instance];

					if (instanceData && instanceData.booted) {
						this.updateInstance(instance, {
							currentPage: 1,
						});

						if (this.needsGlobalCounts(instance)) {
							this.getCounts(instance, instanceData.query);
						}

						return this.search(instance, instanceData.query);
					} else {
						return;
					}
				}));
			},

			async searchAndResetPage(instance: string, query: string) {
				this.updateInstance(instance, {
					currentPage: 1,
				});

				return this.search(instance, query);
			},

			getCountSearchOptions(instanceData: StoreInstance<K, F>, options: JmiSearchParams<F>): JmiSearchParams<F> {
				return options;
			},

			getSearchOptions(instanceData: StoreInstance<K, F>, options: JmiSearchParams<F>, instanceName: string): JmiSearchParams<F> {
				return options;
			},

			getMatchesQuery(instanceData: StoreInstance<K, F>, record: K): boolean {
				return !!record.id;
			},

			async getCounts(instance: string, query: string) {
				let result;
				const instanceData = this.instances[instance];
				let options: JmiSearchParams<F> = {
					page: 0,
					filter: [],
					matchingStrategy: MatchingStrategies.ALL,
					facets: facets,
				};

				options = this.getCountSearchOptions(instanceData, options);

				try {
					if (indexKey) {
						result = await this.runMeilisearchSearch(query, options);
					} else {
						result = await this.runBackendSearch(instance, query, options);
					}

					this.instances[instance].totalHits = result.totalHits ?? 0;
					this.instances[instance].facetDistribution = result.facetDistribution ?? {};
					this.instances[instance].countFetchedAt = Date.now();
				} catch (e) {
					if (isError(e)) {
						// Swallow aborted exceptions, but let other exceptions through.
						if (e.message.indexOf("aborted") === -1) {
							throw e;
						}
					} else {
						throw e;
					}
				}
			},

			async runMeilisearchSearch(query: string, options: JmiSearchParams<F>): Promise<SearchResponse<K>> {
				const indexName = this.getIndexName();
				if (indexName) {
					try {
						return await this.searchClient!.index(indexName).search(query, options as SearchParams);
					} catch (error) {
						await useSession().handleError(error);
					}
				} else {
					throw new Error(`No index found for ${indexKey}.`);
				}
			},

			toQueryString(data: Record<string, string | number | Record<string, string | number>>, prefix = '') {
				let query_items: string[] = [];

				Object.keys(data).map((key) => {
					const val = data[key];
					const query_key = prefix ? `${prefix}[${key}]` : key;
					if (val !== null && typeof val === 'object') {
						query_items = [...query_items, ...this.toQueryString(val, key)];
					} else {
						query_items.push(`${query_key}=${encodeURIComponent(val)}`);
					}
				});

				return query_items;
			},

			async runBackendSearch(instance: string, query: string, options: JmiSearchParams<F>): Promise<SearchResponse<K>> {
				const {get} = useJmiFetch();

				options.q = query;
				const searchParams = this.toQueryString(options).join('&');

				let requestOptions: FetchOptions;

				const controller = new AbortController();
				this.instances[instance].abortControllers.push(controller);

				requestOptions = {
					signal: controller.signal
				};

				return (await get(endpoint + '?' + searchParams, requestOptions)) as SearchResponse<K>;
			},

			async refreshPendingChanges() {
				let attempts = 0;

				const getChanges = async () => {
					if (this.pendingChanges !== undefined && Object.keys(this.pendingChanges).length > 0 && this.instances['refresh-pending-changes']) {
						// By force-fetching all the records with the IDs we have in pending changes,
						// the search function will automatically remove them from pending changes as soon as they're updated.
						// And we keep doing this search until everything's updated.
						console.log(`Searching for ${Object.keys(this.pendingChanges).length} records...`);
						await this.setFacet('refresh-pending-changes', 'id', Object.keys(this.pendingChanges));
						await this.searchAndResetPage('refresh-pending-changes', this.instances['refresh-pending-changes'].query);

						const fetchedIds: string[] = this.instances['refresh-pending-changes'].queryData.map((record) => {
							// Ensure we always return strings.
							return record.id + '';
						});

						Object.keys(this.pendingChanges).forEach((id) => {
							const record = this.pendingChanges?.[id];
							const wasRecentlyCreated = (isDeletedRecord(record) ? false : record?.wasRecentlyCreated) ?? false;

							if (fetchedIds.indexOf(id) === -1 && !wasRecentlyCreated && !this.instances['refresh-pending-changes']?.deletedRecords.includes(id)) {
								// This record is no longer in Meilisearch, and can be safely removed.
								this.removePendingChange(id);
							}
						});

						if (Object.keys(this.pendingChanges).length > 0) {
							console.log(`Still have ${Object.keys(this.pendingChanges).length} records out of date...`);
							// We still have out-of-date results, let's re-fetch after a timeout:
							await (new Promise(resolve => setTimeout(resolve, 500)));

							attempts += 1;
							// 120 attempts * 500ms = 60 seconds; if records haven't updated within a minute, let's just clear pending changes and move on.
							if (attempts <= 120) {
								await getChanges();
							} else {
								// Too many attempts, let's clear pending changes to avoid an infinite loop:
								console.log(`Too many attempts, clearing pending changes!`);
								localStorage.setItem(`pending-changes-${storeId}`, '{}');
								this.pendingChanges = {};
								// All records are up-to-date, we can now force a refresh for all queries.
								await this.refresh();
								console.log(`All up to date!`);
							}
						} else {
							// All records are up-to-date, we can now force a refresh for all queries.
							await this.refresh();
							console.log(`All up to date!`);
						}
					}
				};

				if (!this.isRefreshingPendingChanges && this.pendingChanges !== undefined && Object.keys(this.pendingChanges).length > 0) {
					this.isRefreshingPendingChanges = true;
					if (typeof this.instances['refresh-pending-changes'] === "undefined") {
						// This is here to force TypeScript to understand that ID is never going to be extended to a different type.
						const isFacetList = (value: any): value is F => {
							return true;
						};

						const queryFacets = {
							id: Object.keys(this.pendingChanges)
						};

						if (isFacetList(queryFacets)) {
							await this.boot('refresh-pending-changes', {
								useGlobalCounts: false,
								useCachedFacets: false,
								queryFacets: queryFacets,
							});
						}
					}

					await getChanges();
					this.isRefreshingPendingChanges = false;
				}
			},

			addPendingChange(record: K | DeletedRecord, isNew: boolean) {
				if (typeof this.pendingChanges === "undefined") {
					this.pendingChanges = {};
				}

				if (isNew && !isDeletedRecord(record)) {
					record.wasRecentlyCreated = true;
				}

				// Update all existing records with the same ID, if any.
				for (const [instance, instanceData] of Object.entries(this.instances)) {
					for (const [index, instanceRecord] of instanceData.queryData.entries()) {
						if (instanceRecord.id === record.id) {
							if (isDeletedRecord(record)) {
								this.instances[instance].queryData.splice(index, 1);
							} else {
								this.instances[instance].queryData[index] = record;
							}
						}
					}
				}

				this.pendingChanges[record.id] = record;
				console.log(`Adding ${storeId} ${record.id}...`);
				localStorage.setItem(`pending-changes-${storeId}`, JSON.stringify(this.pendingChanges));
				void this.refreshPendingChanges();
			},

			removePendingChange(id: string | number) {
				if (typeof this.pendingChanges === "undefined") {
					this.pendingChanges = {};
				}

				delete this.pendingChanges[id];
				console.log(`Deleting ${storeId} ${id}...`);
				localStorage.setItem(`pending-changes-${storeId}`, JSON.stringify(this.pendingChanges));
			},

			async search(instance: string, query: string, extra?: JmiSearchParams<F>) {
				const instanceData = this.instances[instance];

				if (!instanceData) {
					throw new Error(`Trying to run a search for a non-existent instance (${instance}).`);
				}

				const needsGlobalCounts = this.needsGlobalCounts(instance);
				let result;
				const processingTimeStart = Date.now();
				let options: JmiSearchParams<F> = {
					hitsPerPage: instanceData.perPage,
					page: instanceData.currentPage,
					filter: [],
					matchingStrategy: MatchingStrategies.ALL,
					highlightPreTag: "<span class='font-medium text-jmi-purple1'>",
					highlightPostTag: "</span>",
					...extra
				};

				if (instanceData.sort) {
					const sortArray = [];
					for (const key in instanceData.sort) {
						sortArray.push(key + ':' + instanceData.sort[key])
					}
					options.sort = sortArray;
				}

				this.instances[instance].isSearching = true;

				// Abort previously ongoing requests.
				if (!indexKey) {
					instanceData.abortControllers.forEach((controller) => {
						controller.abort();
					});
					this.instances[instance].abortControllers = [];
				}

				// If we're not using facets, we don't need getCounts and can skip the extra request.
				if (!needsGlobalCounts) {
					options.facets = facets;
				}

				// The handling for the 'id' facet is always the same, and can be abstracted.
				if (typeof instanceData.queryFacets.id !== "undefined" && instanceData.queryFacets.id !== null) {
					const ids: Array<string | number> = (typeof instanceData.queryFacets.id === "number" || typeof instanceData.queryFacets.id === "string") ? [instanceData.queryFacets.id] : instanceData.queryFacets.id;
					let filter: string[] = [];
					filter = [];

					ids.forEach((value: string | number) => {
						filter.push(`"${value}"`);
					});

					options.filter.push(`id IN [${filter.join(", ")}]`);
				}

				options = this.getSearchOptions(instanceData, options, instance);

				try {
					if (indexKey) {
						result = this.runMeilisearchSearch(query, options);
					} else {
						result = this.runBackendSearch(instance, query, options);
					}

					// If we're using facets, then we need to use getCounts to get an accurate number for -all- facets, not just the selected one.
					if (instanceData.previousQuery !== query && needsGlobalCounts) {
						void this.getCounts(instance, query);
					}

					// We trigger the search request 1st, then we trigger the global count request,
					// then we wait for the search request to finish and handle the results.
					// The reason for this is to make it more likely for the search request to come back first.
					result = await result;

					const queryDataIds: string[] = [];
					let countAdjustment = 0;

					const deletedRecords: Array<string> = [];

					this.updateInstance(instance, {
						queryData: result.hits.map((item) => {
							queryDataIds.push(item.id + '');

							if (this.pendingChanges !== undefined && typeof this.pendingChanges[item.id] !== "undefined") {
								const pendingChange = this.pendingChanges[item.id];
								if (pendingChange) {
									if (isDeletedRecord(pendingChange)) {
										// The record still exists in meilisearch, so pretend it disappeared.
										deletedRecords.push(item.id + '');
										return null;
									} else {
										const updated_at = pendingChange?.updated_at ?? 0;

										if (updated_at > item.updated_at || typeof item.updated_at === "undefined") {
											// Our stashed version is newer than what we got from meilisearch, let's return our stashed version.
											return this.pendingChanges[item.id] as ExtendedK;
										} else {
											// Our stashed version is older than what we got from meilisearch, let's drop it.
											this.removePendingChange(item.id);
											return item;
										}
									}
								}
							} else {
								return item;
							}
						}).filter((item) => {
							return item !== null && typeof item !== "undefined";
						})
					});

					if (this.pendingChanges !== undefined) {
						if (instance === 'refresh-pending-changes') {
							if (this.instances['refresh-pending-changes']) {
								this.instances['refresh-pending-changes'].deletedRecords = deletedRecords;
							} else {
								throw new Error("Trying to update deletedRecords on a non-existent instance.");
							}
						} else {
							Object.keys(this.pendingChanges).forEach((id) => {
								const item = this.pendingChanges?.[id];

								// Check if this record should even show up in this query anymore.
								if (this.getMatchesQuery(this.instances[instance], item)) {
									if (queryDataIds.indexOf(id) === -1) {
										// Add the record to the top of queryData.
										this.instances[instance].queryData.unshift(item);
										countAdjustment += 1;
									}
								} else {
									if (queryDataIds.indexOf(id) !== -1) {
										// Remove the record from the queryData.
										this.instances[instance].queryData.forEach((item: Record<string, any>, index: number) => {
											if ((item.id + '') === id) {
												this.instances[instance].queryData.splice(index, 1);
												countAdjustment -= 1;
											}
										});
									}
								}
							});
						}
					}

					this.instances[instance].query = query;
					this.instances[instance].totalHitsActive = (result.totalHits ?? 0) + countAdjustment;
					this.instances[instance].countFetchedAt = Date.now();

					if (!needsGlobalCounts) {
						// Don't update counts if we've just started using a facet.
						this.instances[instance].totalHits = (result.totalHits ?? 0) + countAdjustment;
						this.instances[instance].facetDistribution = result.facetDistribution ?? {};
					}

					this.mostRecentQueriesMs.push(Date.now() - processingTimeStart);
					if (this.mostRecentQueriesMs.length > 5) {
						this.mostRecentQueriesMs.shift();
					}

					this.instances[instance].isSearching = false;
					this.instances[instance].hasCompletedFirstSearch = true;
					this.instances[instance].previousQuery = instanceData.query;
				} catch (e) {
					if (isError(e)) {
						// Swallow aborted exceptions, but let other exceptions through.
						if (e.message.indexOf("aborted") === -1) {
							this.instances[instance].isSearching = false;
							throw e;
						}
					} else {
						throw e;
					}
				}
			},

			setFacet<SingleFacet extends keyof F = keyof F>(instance: string, facet: SingleFacet, value: F[SingleFacet] | undefined, refresh = true) {
				const instanceData = this.instances[instance];

				if (!instanceData) {
					throw new Error("Trying to set a facet on a non-existent instance.");
				}

				let currentValue = undefined;

				if (typeof instanceData.queryFacets[facet] !== 'undefined') {
					currentValue = JSON.stringify(instanceData.queryFacets[facet]);
				}

				if (currentValue === JSON.stringify(value)) {
					// It's the same value as before, don't trigger an update.
					return;
				}

				const existingQueryFacets = instanceData.queryFacets;

				if (value === undefined) {
					delete existingQueryFacets[facet];
				} else {
					existingQueryFacets[facet] = value;
				}

				this.updateInstance(instance, {
					queryFacets: existingQueryFacets
				});

				localStorage.setItem(`facets-${storeId}-${instance}`, JSON.stringify(existingQueryFacets));

				if (refresh) {
					void this.searchAndResetPage(instance, instanceData.query);
				}
			},

			setSort(instance: string, sort, refresh = true) {
				this.instances[instance].sort = sort;
				if (refresh) {
					void this.searchAndResetPage(instance, this.instances[instance].query);
				}
			},

			async openRecord(id: string | number) {
				if (!this.currentRecord || this.currentRecord.id !== id) {
					this.openingRecord = true;
					this.requestCode = 200;
					// If the request fails, we don't want to keep the loading state in loading.
					try {
						this.currentRecord = await this.submit<UnwrapRef<ExtendedK>>(undefined, 'GET', id);
					} catch (e) {
						await useSession().handleError(e, [404]);
					} finally {
						this.openingRecord = false;
					}
				}
			},

			async closeRecord() {
				this.currentRecord = undefined;
				this.openingRecord = false;
			},

			shutdown(instance = "default") {
				delete this.instances[instance];
			},

			restartMeiliSearchClient() {
				if (indexKey) {
					const session = useSession();
					const runtimeConfig = useRuntimeConfig();

					this.searchClient = new MeiliSearch({
						host: runtimeConfig.public["meilisearchHost"],
						apiKey: session.user!.meilisearch_token
					});
				}

				return this;
			},

			async boot(instance = "default", options: StoreInstanceOptions<F> = {}) {
				if (indexKey && !this.searchClient) {
					this.restartMeiliSearchClient();
				}

				// Load pending changes from localStorage, if there are any.
				if (this.pendingChanges === undefined) {
					const pending_changes = localStorage.getItem(`pending-changes-${storeId}`);
					if (pending_changes) {
						this.pendingChanges = JSON.parse(pending_changes) as Record<string, ExtendedK>;

						if (Object.keys(this.pendingChanges).length > 0) {
							void this.refreshPendingChanges();
						}
					}
				}

				if (!this.instances[instance] || !this.instances[instance].booted) {
					this.instances[instance] = {
						booted: true,
						hasCompletedFirstSearch: false,
						query: "",
						previousQuery: undefined,
						queryData: [],
						queryFacets: {},
						totalHits: 0,
						totalHitsActive: 0,
						perPage: 20,
						useGlobalCounts: undefined,
						currentPage: 1,
						facetDistribution: {},
						countFetchedAt: undefined,
						isSearching: false,
						abortControllers: [],
						...options
					};

					const cachedFacets = localStorage.getItem(`facets-${storeId}-${instance}`);
					if (cachedFacets && (options.useCachedFacets ?? true)) {
						this.instances[instance].queryFacets = JSON.parse(cachedFacets);
					}

					return this.search(instance, "");
				}
			},
			...actions
		},
	});

	if (import.meta.hot) {
		import.meta.hot.accept(acceptHMRUpdate(store, import.meta.hot))
	}

	return store;
}
