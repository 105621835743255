import {useSession} from "~/stores/session";
import type {FetchOptions} from "ofetch";

type JmiHeaders = {
	"JMI-Origin": string,
	"JMI-Partner-Id"?: string
}

export type Method = 'get' | 'options' | 'post' | 'put' | 'patch' | 'delete' | 'GET' | 'OPTIONS' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type JmiFetchOptions = FetchOptions & { method: Method };

export default function () {
	const session = useSession();
	const runtimeConfig = useRuntimeConfig();

	const fetch = async <K extends object>(url: string, requestOptions: Partial<JmiFetchOptions> = {}) => {
		const headers: JmiHeaders = {
			'JMI-Origin': runtimeConfig.public['partnerOrigin'],
		};

		if (session.activePartnerId) {
			headers['JMI-Partner-Id'] = session.activePartnerId;
		}

		try {
			return $fetch<K>(runtimeConfig.public['baseURL'] + url, {
				credentials: 'include',
				headers: headers,
				...requestOptions
			});
		} catch (error) {
			// If we have no response code, we have a cors error
			if (!error.statusCode) {
				// If we have a cors error, we logout the user
				session.logout();
			}

			throw error;
		}
	};

	return {
		fetch: fetch,
		get: async <K extends object>(url: string, requestOptions: Partial<JmiFetchOptions> = {}) => {
			return fetch<K>(url, {
				method: "GET",
				...requestOptions
			});
		},
		post: async <K extends object>(url: string, body: Record<string, unknown>, requestOptions: Partial<JmiFetchOptions> = {}) => {
			return fetch<K>(url, {
				method: "POST",
				body: body,
				...requestOptions
			});
		}
	}
}
