import {useSession} from "~/stores/session";
import {JmiSearchParams, StoreInstance} from "~/composables/buildStore";
import {UserRecord} from "~/utils/types";

export const useMovers = buildStore<"movers", UserRecord, UserRecord>(
	"movers",
	"/movers",
	"users",
	[],
	{
		panelOpen: false,
	},
	{},
	{
		getCountSearchOptions(instanceData: StoreInstance<UserRecord>, options: JmiSearchParams): JmiSearchParams {
			const session = useSession();

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			return options;
		},
		getSearchOptions(instanceData: StoreInstance<UserRecord>, options: JmiSearchParams): JmiSearchParams {
			const session = useSession();

			options.attributesToHighlight = ['firstname', 'lastname', 'email', 'phone'];

			options.filter.push(`usertype = 'customer'`);

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			return options;
		},
		userEdit() {
			navigateTo("/movers/edit/" + this.currentRecord?.id);
			this.setPanel(false);
		},
		userStartMove () {
			navigateTo({
				path: '/moves/add',
				query: {
					mover_id: this.currentRecord?.id
				}
			});
			this.setPanel(false);
		},
		setPanel(isOpen : boolean) {
			this.panelOpen = isOpen;
		},
		openPanel(recordId : number) {
			this.openRecord(recordId);
			this.setPanel(true);
		}
	}
);
