import {useSession} from "~/stores/session";

export default defineNuxtRouteMiddleware(async (to) => {
	const session = useSession();
	session.boot(to).catch(() => {
		// If there is a error reloading the user data we just ignore it
		// and let the user go to the login page
	});

	if (session.user?.usertype === "jmi" && session.user?.active_parent_partner_id) {
		return session.handleLoggedIn(to);
	} else if (session.user?.usertype === "partner") {
		return session.handleLoggedIn(to);
	} else {
		return session.handleLoggedOut(to);
	}
});
