import {acceptHMRUpdate} from 'pinia';
import type {PartnerRecord} from "~/utils/types";
import type {FacetList, JmiSearchParams, StoreInstance} from "~/composables/buildStore";

export interface PartnerFacets extends FacetList {
	name: string,
	parentid: string,
	is_parent: boolean,
}

export const usePartners = buildStore<"partners", PartnerRecord, PartnerRecord, PartnerFacets>(
	"partners",
	"/partners",
	"partners",
	[],
	{},
	{},
	{
		getSearchOptions(instanceData: StoreInstance<PartnerRecord, PartnerFacets>, options: JmiSearchParams<PartnerFacets>) {
			options.attributesToHighlight = ['name'];

			if (typeof instanceData.queryFacets.parentid !== "undefined") {
				options.filter.push(`parentid = "${instanceData.queryFacets.parentid}"`);
			}

			if (typeof instanceData.queryFacets.is_parent !== "undefined") {
				options.filter.push(`is_parent = ${instanceData.queryFacets.is_parent ? "true" : "false"}`);
			}

			options.attributesToRetrieve = ['id', 'name', 'ps_strategy', 'allow_no_consent_submissions', 'autocoo', 'void_address'];

			return options;
		}
	}
);

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(usePartners, import.meta.hot));
}
