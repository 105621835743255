import {acceptHMRUpdate} from 'pinia';
import type {GasOrElectricityLookupResponse, JmiAddress, SupplierRecord, SupplierType, CouncilLookupResponse, WaterSewerageLookupResponse} from "~/utils/types";
import type {FacetList, JmiSearchParams, StoreInstance} from "~/composables/buildStore";
import {useSession} from "~/stores/session";
import useJmiFetch from "~/composables/use-jmi-fetch";
import {isGasOrElectricityLookupResponse} from "~/utils/types";

export interface SupplierFacets extends FacetList {
	types: SupplierType
}

export const useSuppliers = buildStore<"suppliers", SupplierRecord, SupplierRecord, SupplierFacets, {
	isLookingUpGas: boolean,
	isLookingUpElectricity: boolean,
	gasLookupResponse: GasOrElectricityLookupResponse | undefined,
	electricityLookupResponse: GasOrElectricityLookupResponse | undefined
}, {
	lookupUrls: () => {
		[prop in SupplierType]: string | null
	},
}, {
	lookupCouncil: (postcode: string) => Promise<CouncilLookupResponse>,
	lookupWaterSewerage: (postcode: string) => Promise<WaterSewerageLookupResponse>,
	lookupGas: (address: JmiAddress) => Promise<void>
	lookupElectricity: (address: JmiAddress) => Promise<void>
}>(
	"suppliers",
	"/suppliers",
	"suppliers",
	["types"],
	{
		isLookingUpGas: false,
		isLookingUpElectricity: false,
		gasLookupResponse: undefined,
		electricityLookupResponse: undefined,
	},
	{
		lookupUrls() {
			return {
				Council: "https://www.gov.uk/find-local-council",
				Gas: "https://www.findmysupplier.energy/",
				Electricity: null,
				Water: "https://www.water.org.uk/advice-for-customers/find-your-supplier",
				Sewerage: "https://www.water.org.uk/advice-for-customers/find-your-supplier",
			};
		},
	},
	{
		async lookupGas(address: JmiAddress): Promise<void> {
			const {post} = useJmiFetch();

			this['isLookingUpGas'] = true;
			this['gasLookupResponse'] = undefined;
			try {
				const response = await post<GasOrElectricityLookupResponse|Record<string, never>>('/lookups/gas', {
					address
				});

				if (isGasOrElectricityLookupResponse(response)) {
					this['gasLookupResponse'] = response;
				}
			} finally {
				this['isLookingUpGas'] = false;
			}
		},

		async lookupElectricity(address: JmiAddress): Promise<void> {
			const {post} = useJmiFetch();

			this['isLookingUpElectricity'] = true;
			this['electricityLookupResponse'] = undefined;
			try {
				const response = await post<GasOrElectricityLookupResponse|Record<string, never>>('/lookups/electricity', {
					address
				});

				if (isGasOrElectricityLookupResponse(response)) {
					this['electricityLookupResponse'] = response;
				}
			} finally {
				this['isLookingUpElectricity'] = false;
			}
		},

		async lookupCouncil(postcode: string): Promise<CouncilLookupResponse> {
			const {get} = useJmiFetch();
			return get<CouncilLookupResponse>(`/lookups/council?postcode=` + postcode);
		},
		async lookupWaterSewerage(postcode: string): Promise<WaterSewerageLookupResponse> {
			const {get} = useJmiFetch();
			return get<WaterSewerageLookupResponse>(`/lookups/water-sewerage?postcode=` + postcode);
		},

		getCountSearchOptions(_instanceData: StoreInstance<SupplierRecord, SupplierFacets>, options: JmiSearchParams<SupplierFacets>) {
			const session = useSession();

			if (session.activePartnerId) {
				options.filter.push(`partnerid = '${session.activePartnerId}'`);
			}

			return options;
		},
		getSearchOptions(instanceData: StoreInstance<SupplierRecord, SupplierFacets>, options: JmiSearchParams<SupplierFacets>) {
			options.attributesToHighlight = ['name'];

			options.filter.push(`active = true`);

			if (instanceData.queryFacets.types) {
				options.filter.push(`types = "${instanceData.queryFacets.types}"`);
			}

			options.attributesToRetrieve = ['id', 'name', 'suppliernumber', 'active', 'types', 'does_not_accept_coos', 'website', 'phone'];

			return options;
		}
	}
);

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useSuppliers, import.meta.hot));
}
